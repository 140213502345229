import { createApp } from 'vue'
import App from './App.vue'

import pinia from '@/store/index'
import ElementPlus from 'element-plus'
import router from '@/router'
import I18n from '@/language'
import * as Icons from '@element-plus/icons-vue'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import direct from '@/directive/index'

import 'element-plus/dist/index.css'
import '@/assets/css/index.css'

dayjs.extend(duration)

const app = createApp(App)

Object.keys(Icons).forEach(key => {
  app.component(key, Icons[key as keyof typeof Icons])
})

direct(app)
app.use(pinia)
app.use(ElementPlus)
app.use(router)
app.use(I18n)

app.mount('#app')
