import { APIList } from './typings'
import identify from './modules/identify'
import bi from './modules/bi'
import user from './modules/user'
import project from './modules/project'
import leave from './modules/leave'
import oss from './modules/oss'

const apiMap: APIList = {
  identify: identify,
  bi: bi,
  user: user,
  project: project,
  leave: leave,
  oss: oss,
}


export default apiMap
