
import { GlobalStore } from '@/store'

export type IPermissionType = 'or' | 'and'
export function checkPermission(permission:string|Array<string>, type:IPermissionType = 'or'):boolean {
  const value:Array<string> = typeof permission === 'string' ? [permission] : permission
  const roles:Array<string> = (GlobalStore().userInfo.permissions || []) as Array<string>
  for (let index = 0; index < roles.length; index++) {
    const role = roles[index]
    if (role === '*') {
      return true
    }
  }

  const isShow = type === 'and'
    ? value.every(v => roles.includes(v))
    : value.some(v => roles.includes(v))

  return isShow
}
