import { APIBI } from '../typings'
import request from '../request'
import { Download } from '../typings/download'

export default {
  syncDailyWorklog: (beginDate: Date, endDate: Date): Promise<any> => {
    return request.post<any>('api/pmf/v1/bi/syncDailyWorklog', {
      beginDate, endDate,
    })
  },
  dateTip:(param: Record<string, any>): Promise<any> => {
    return request.get<any>('/api/pmf/v1/dates', param)
  },
  summary: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/summary', param)
  },
  project: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/project', param)
  },
  sponsor: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/sponsor', param)
  },
  serving: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/serving', param)
  },
  pm: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/pm', param)
  },
  staff: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/staff', param)
  },

  summaryExport: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/exportSummary', param)
  },
  projectExport: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/exportProject', param)
  },
  sponsorExport: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/exportSponsor', param)
  },
  servingExport: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/exportServing', param)
  },
  pmExport: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/exportPm', param)
  },
  staffExport: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/exportStaff', param)
  },

  staffEfficiency: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/staff_efficiency', param)
  },
  staffList: (): Promise<any> => {
    return request.get<any>('/api/pmf/v1/users/permissionUsers')
  },
  list: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/list', param)
  },
  pivotByStaff: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/pivotByStaff', param, {
      responseType: 'blob',
    })
  },
  pivotByProject: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/pivotByProject', param, {
      responseType: 'blob',
    })
  },
  sync: (param: Record<string, any>): Promise<any> => {
    return request.post<any>('api/pmf/v1/bi/syncDailyWorklog', param)
  },
  syncLeave: (param: Record<string, any>): Promise<any> => {
    return request.post<any>('api/pmf/v1/bi/syncLeave', param)
  },
  syncHomeworking: (param: Record<string, any>): Promise<any> => {
    return request.post<any>('api/pmf/v1/bi/syncHomeworking', param)
  },
  syncUser: (): Promise<any> => {
    return request.post<any>('api/pmf/v1/bi/syncUser', {})
  },
  options: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/options', param)
  },
  exportWorklog: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/exportWorklog', param)
  },
  exportUseRate: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/exportEfficiency', param)
  },
  exportPerformance: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/exportPerformance', param)
  },
  exportStaffEfficiency: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/exportStaffEfficiency', param)
  },
  saveView: (data: Record<string, any>): Promise<any> => {
    return request.post<any>('api/pmf/v1/bi/views', data)
  },
  listView: (): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/views')
  },
  deleteView: (id: string|number): Promise<any> => {
    return request.delete<any>(`api/pmf/v1/bi/views/${id}`)
  },
  listDownload: (): Promise<{downloads: Download[]}> => {
    return request.get<any>('api/pmf/v1/downloads')
  },
  staffApporation: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/staffApportion', param)
  },
  exportStaffApporation: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/exportStaffApportion', param)
  },
  projectApporation: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/projectApportion', param)
  },
  exportProjectApporation: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/exportProjectApportion', param)
  },
  taskSchedule: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/taskSchedule', param)
  },
  teamOutput: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/teamOutput', param)
  },
  attendances: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/attendances', param)
  },
  users: (): Promise<any> => {
    return request.get<any>('api/pmf/v1/users/forAttendance')
  },
  groups: (): Promise<any> => {
    return request.get<any>('api/pmf/v1/groups')
  },
  sites: (): Promise<any> => {
    return request.get<any>('api/pmf/v1/users/sites')
  },
  subGroups: (): Promise<any> => {
    return request.get<any>('api/pmf/v1/subGroups')
  },
  bos: (): Promise<any> => {
    return request.get<any>('api/pmf/v1/users/bos')
  },
} as APIBI
