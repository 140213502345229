import { APIIdentify } from '../typings'
import request from '../request'

export default {
  tradeToken: (session: string): Promise<any> => {
    return request.post<any>('api/pmf/v1/idenfity/tradeToken', {
      session,
    })
  },
  info: (): Promise<any> => {
    return request.get<any>('api/pmf/v1/auth/info')
  },
} as APIIdentify
