import { Layout } from '@/router/constant'
import { RouteRecordRaw } from 'vue-router'

// 首页模块
const homeRouter: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Layout,
    meta: {
      title: '首页',
      isHidden: true,
      sort: -1,
      key: 'home',
      requiresAuth: false,
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/home/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '首页',
          key: 'home',
          icon: 'Compass',
          // isHidden: true,
        },
      },
    ],
  },
]

export default homeRouter
