import { Layout } from '@/router/constant'
import { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: '/leaveManage',
    redirect: '/leaves',
    component: Layout,
    meta: {
      keepAlive: true,
      requiresAuth: true,
      title: '请假',
      sort: 1,
      key: 'BILeaveManagement',
    },
    children: [
      {
        path: '/leaves',
        name: 'leaves',
        component: () => import('@/views/leaves/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '请假',
          key: 'BILeaveManagement',
          icon: 'DocumentDelete',
        },
      },
    ],
  },
] as Array<RouteRecordRaw>
