import { Layout } from '@/router/constant'
import { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: '/summaryBi',
    redirect: '/summary',
    component: Layout,
    meta: {
      keepAlive: true,
      requiresAuth: true,
      title: '报表',
      sort: 1,
      key: 'summary',
      icon: 'DataBoard',
    },
    children: [
      {
        path: '/summary',
        name: 'summary',
        component: () => import('@/views/summary/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '汇总',
          key: 'BISummaryManagement',
          icon: 'MoreFilled',
        },
      },
      {
        path: '/project',
        name: 'project',
        component: () => import('@/views/summary/project.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '项目报表',
          key: 'BIProjectManagement',
          icon: 'coin',
        },
      },
      {
        path: '/sponsor',
        name: 'sponsor',
        component: () => import('@/views/summary/sponsor.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '申办方报表',
          key: 'BISponsorManagement',
          icon: 'GoldMedal',
        },
      },
      {
        path: '/serving',
        name: 'serving',
        component: () => import('@/views/summary/serving.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '服务项报表',
          key: 'BIServingManagement',
          icon: 'CollectionTag',
        },
      },
      {
        path: '/pm',
        name: 'pm',
        component: () => import('@/views/summary/pm.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: 'pm服务项报表',
          key: 'BIPMServingManagement',
          icon: 'MagicStick',
        },
      },
      {
        path: '/staff',
        name: 'staff',
        component: () => import('@/views/summary/staff.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '员工工时报表',
          key: 'BIStaffManagement',
          icon: 'user',
        },
      },
      {
        path: '/staff_efficiency',
        name: 'staff_efficiency',
        component: () => import('@/views/summary/staff_efficiency.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '员工利用率报表',
          key: 'BIStaffEfficiencyManagement',
          icon: 'Tickets',
        },
      },
      {
        path: '/staff_apportion',
        name: 'staff_apportion',
        component: () => import('@/views/summary/staff_apportion.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '员工工时分摊',
          key: 'BIStaffApportionManagement',
          icon: 'Document',
        },
      },
      {
        path: '/project_apportion',
        name: 'project_apportion',
        component: () => import('@/views/summary/project_apportion.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '项目工时分摊',
          key: 'BIProjectApportionManagement',
          icon: 'Memo',
        },
      },
      {
        path: '/task_schedule',
        name: 'task_schedule',
        component: () => import('@/views/summary/task_schedule.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '进度报表',
          key: 'BIProjectScheduleManagement',
          icon: 'collection',
        },
      },
      {
        path: '/team_output',
        name: 'team_output',
        component: () => import('@/views/summary/team_output.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '团队产出报表',
          key: 'BITeamOutputManagement',
          icon: 'postcard',
        },
      },
    ],
  },
  {
    path: '/staffSummaryBi',
    redirect: '/staffSummary',
    component: Layout,
    meta: {
      keepAlive: true,
      requiresAuth: true,
      title: '汇总',
      sort: 1,
      key: 'staffSummary',
    },
    children: [
      {
        path: '/staffSummary',
        name: 'staffSummary',
        component: () => import('@/views/staffSummary/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: 'staff分析',
          key: 'BIStaffAnalysisManagement',
          icon: 'list',
        },
      },
    ],
  },
  {
    path: '/projectSummaryBi',
    redirect: '/projectSummary',
    component: Layout,
    meta: {
      keepAlive: true,
      requiresAuth: true,
      title: '汇总',
      sort: 1,
      key: 'projectSummary',
    },
    children: [
      {
        path: '/projectSummary',
        name: 'projectSummary',
        component: () => import('@/views/projectSummary/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: 'project分析',
          key: 'BIProjectAnalysisManagement',
          icon: 'Reading',
        },
      },
    ],
  },
  {
    path: '/syncBi',
    redirect: '/sync',
    component: Layout,
    meta: {
      keepAlive: true,
      requiresAuth: true,
      title: '汇总',
      sort: 1,
      key: 'sync',
    },
    children: [
      {
        path: '/sync',
        name: 'sync',
        component: () => import('@/views/sync/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '数据同步',
          key: 'BIDataSyncManagement',
          icon: 'Refresh',
        },
      },
    ],
  },
  {
    path: '/dataExport',
    redirect: '/export',
    component: Layout,
    meta: {
      keepAlive: true,
      requiresAuth: true,
      title: '汇总',
      sort: 1,
      key: 'dataExport',
    },
    children: [
      {
        path: '/export',
        name: 'export',
        component: () => import('@/views/dataExport/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '数据提取',
          key: 'BIDataExportManagement',
          icon: 'crop',
        },
      },
    ],
  },
  {
    path: '/staffAttendanceViewer',
    redirect: '/staff_attendance_viewer',
    component: Layout,
    meta: {
      keepAlive: true,
      requiresAuth: true,
      title: '汇总',
      sort: 1,
      key: 'dataExport',
    },
    children: [
      {
        path: '/staff_attendance_viewer',
        name: 'staff_attendance_viewer',
        component: () => import('@/views/staffAttendanceViewer/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '员工假勤情况',
          key: 'BIAttendanceRead',
          icon: 'postcard', // todo
        },
      },
    ],
  },
] as Array<RouteRecordRaw>
