import { APILeave } from '../typings'
import request from '../request'

export default {
  create: (data: Record<string, any>): Promise<any> => {
    return request.post<any>('api/pmf/v1/bi/leaves', data)
  },
  update: (data: any): Promise<any> => {
    return request.put<any>(`api/pmf/v1/bi/leaves/${data.id}`, data)
  },
  delete: (id: string): Promise<any> => {
    return request.delete<any>(`api/pmf/v1/bi/leaves/${id}`)
  },
  list: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/bi/leaves', param)
  },
  import: (data: Record<string, any>): Promise<any> => {
    return request.post<any>('api/pmf/v1/bi/leaves/import', data)
  },
} as APILeave
