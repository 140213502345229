<template>
  <el-config-provider :locale="i18nLocale" :button="config" :size="assemblySize">
    <router-view />
  </el-config-provider>
</template>

<script setup lang="ts">
import { GlobalStore } from '@/store'
import { computed, reactive } from 'vue'
// 配置element中英文
import zhCn from 'element-plus/dist/locale/zh-cn'
import en from 'element-plus/dist/locale/en'

const globalStore = GlobalStore()
// 配置element按钮文字中间是否有空格
const config = reactive({
  autoInsertSpace: false,
})

// element 语言配置
const i18nLocale = computed((): any => {
  if (globalStore.language && globalStore.language == 'zh') return zhCn
  if (globalStore.language == 'en') return en
  return zhCn
})

// 配置全局组件大小 (small/default(medium)/large)
const assemblySize = computed((): string => globalStore.assemblySize)
</script>
