import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// * 导入所有router
const metaRouters = import.meta.glob('./modules/*.ts', { eager: true }) as any

// * 处理路由
export const routerArray: RouteRecordRaw[] = []
Object.keys(metaRouters).forEach(item => {
  Object.keys(metaRouters[item]).forEach((key: any) => {
    routerArray.push(...metaRouters[item][key])
  })
})

const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      requiresAuth: false,
      title: '登录页',
      key: 'login',
      isHidden: true,
    },
  },
  {
    path: '/403',
    component: () => import('@/components/ErrorMessage/403.vue'),
    meta: {
      requiresAuth: false,
      title: '403',
      isHidden: true,
    },
  },
  {
    path: '/500',
    component: () => import('@/components/ErrorMessage/500.vue'),
    meta: {
      requiresAuth: false,
      title: '500',
      isHidden: true,
    },
  },
  {
    path: '/404',
    component: () => import('@/components/ErrorMessage/404.vue'),
    meta: {
      requiresAuth: false,
      title: '404',
      isHidden: true,
    },
  },
  ...routerArray,
  {
    // 找不到路由重定向到404页面
    path: '/:pathMatch(.*)',
    redirect: '/404',
    meta: {
      isHidden: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  strict: false,
  // 切换页面，滚动到最顶部
  scrollBehavior: () => ({ left: 0, top: 0 }),
})

export default router
