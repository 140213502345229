import { defineStore } from 'pinia'
import { GlobalState } from './typings'
import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
import piniaPersistConfig from '@/config/piniaPersist'
import API from '@/api'
import type { UserInfo } from '@/api/typings/user'

// defineStore 调用后返回一个函数，调用该函数获得 Store 实体
export const GlobalStore = defineStore({
  // id: 必须的，在所有 Store 中唯一
  id: 'GlobalState',
  // state: 返回对象的函数
  state: (): GlobalState => ({
    // Token
    token: '',
    // userInfo
    userInfo: {} as UserInfo,
    // element组件大小
    assemblySize: 'default',
    // language
    language: '',
  }),
  getters: {},
  actions: {
    async tradeToken(session: string) {
      const data = await API.identify.tradeToken(session)
      if (!data) return
      this.token = data.data
      await this.getInfo()
    },
    // set token
    setToken(token: string) {
      this.token = token
    },
    // set AssemblySize
    setAssemblySizeSize(assemblySize: string) {
      this.assemblySize = assemblySize
    },
    // updateLanguage
    updateLanguage(language: string) {
      this.language = language
    },
    async getInfo() {
      const info = await API.identify.info()
      this.userInfo = info.data
    },
  },
  persist: piniaPersistConfig('GlobalState'),
})

// piniaPersist(持久化)
const pinia = createPinia()
pinia.use(piniaPersist)

export default pinia
