import { APIProject } from '../typings'
import request from '../request'

export default {
  projectList: (param: Record<string, any>): Promise<any> => {
    return request.get<any>('api/pmf/v1/projects', param)
  },
  servingListByProject: (projectId: string | number): Promise<any> => {
    return request.get<any>('api/pmf/v1/service/getServiceByProject', {
      project: projectId,
    })
  },
} as APIProject
